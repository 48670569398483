import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const SignUpAsASoleTrade = () => {
  return (
    <Layout>
      <Seo
        title="Sign Up as a Sole Trade"
        description={`During the self sign-up process with individual onboarding, users receive clear instructions on attaining verification status after submitting all the required details via the Synkli App.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`sign-up-as-a-sole-trade`} />
    </Layout>
  )
}

export default SignUpAsASoleTrade
